import { useEffect, useRef, useState } from "react";
import grapesjs from "grapesjs";
import React from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import websitePlugin from 'grapesjs-preset-webpage';
import basicBlockPlugin from 'grapesjs-blocks-basic';
import formPlugin from 'grapesjs-plugin-forms';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [templateName, setTemplateName] = useState("");
  const params = new URLSearchParams(window.location.search);
  const useremail = params.get('user_email');
  const user_id = params.get('user_id');
  const status = params.get('status');
  const editorRef = useRef(null); 
  const lead_api = "https://dashboard.sendwithhma.com/api/create_list";

  useEffect(() => {
    const fetch_templates = async () => {
      const tost_id = toast.loading("Please wait while we load your template");
      try {
        const load_template_Name = params.get('template_name');
        const htmlResponse = await fetch(`/templates/${load_template_Name}/index.html`);
        if (!htmlResponse.ok) {
          toast.update(tost_id, { render: "Something went wrong", type: "error", isLoading: false });
          return;
        }

        let htmlContent = await htmlResponse.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const editor = grapesjs.init({
          container: '#gjs',
          width: '100%',
          height: '100vh',
          fromElement: false,
          plugins: [websitePlugin, basicBlockPlugin, formPlugin],
          storageManager: false,
          assetManager: {
            upload: false,
            assets: [],
          },
          // Your panels configuration (if necessary)
        });
        
        // Function to close all categories
        const closeAllCategories = () => {
          const categories = editor.BlockManager.getCategories();
          categories.each(category => {
            category.set('open', false); // Close each category
          });
        };
        
        // Ensure blocks panel categories are closed initially
        editor.on('load', () => {
          closeAllCategories();
        });
        
        // If you have dynamic additions of blocks or categories, ensure to close them too
        editor.on('block:add', () => {
          closeAllCategories();
        });
        
        
        custom_form_block(editor);
        navigation_block(editor);
        feature_block(editor);
        about_block(editor);
        gallery_form_block(editor);
        testimonial_form_block(editor);
        footer_block(editor);
        thank_you_block(editor);
        editorRef.current = editor;

        const cssLinks = doc.querySelectorAll('link[rel="stylesheet"]');
        for (const link of cssLinks) {
          const cssUrl = `/templates/${load_template_Name}/${link.getAttribute('href')}`;
          try {
            const cssResponse = await fetch(cssUrl);
            if (!cssResponse.ok) {
              const errorText = await cssResponse.text();
              console.log(errorText);
            } else {
              const cssContent = await cssResponse.text();
              const style = document.createElement('style');
              style.textContent = cssContent;
              document.head.appendChild(style);
              editor.addStyle(cssContent);
            }
          } catch (error) {
            console.error('Error loading CSS file:', cssUrl, error);
          }
        }

        const loadScript = (src) => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;
            script.onload = () => {
              resolve();
            };
            script.onerror = (error) => {
              console.error(`Failed to load JS file: ${src}`, error);
              reject(error);
            };
            document.body.appendChild(script);
          });
        };

        const scriptElements = doc.querySelectorAll('script[src]');
        for (const script of scriptElements) {
          const jsUrl = `/templates/${load_template_Name}/${script.getAttribute('src')}`;
          try {
            await loadScript(jsUrl);
          } catch (error) {
            console.error('Error loading JS file:', jsUrl, error);
          }
        }

        // Handle images with relative paths
        const imageElements = doc.querySelectorAll('img[src]');
        for (const img of imageElements) {
          const src = img.getAttribute('src');
          if (!src.startsWith('http') && !src.startsWith('//')) {
            const imageUrl = `/templates/${load_template_Name}/${src}`;
            img.setAttribute('src', imageUrl);
          }
        }

        const bodyContent = doc.body.innerHTML;
        editor.setComponents(bodyContent);

        // Use MutationObserver to wait for the iframe to be ready
        const iframeObserver = new MutationObserver(() => {
          const iframe = editor.Canvas.getFrameEl();
          if (iframe) {
            iframeObserver.disconnect();
            iframe.addEventListener('load', () => {
              const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
              iframeDocument.querySelectorAll('form').forEach(form => {
                form.addEventListener('submit', function(event) {
                  event.preventDefault();
                  const formEmail = form.querySelector('input[type="email"]').value;

                  fetch(lead_api, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      form_email: formEmail,
                      user_email: useremail,
                      user_id: user_id,
                      name: templateName
                    })
                  })
                  .then(response => response.json())
                  .then(data => {
                    console.log('Success:', data);
                    toast.success("Form submitted successfully");
                  })
                  .catch((error) => {
                    console.error('Error:', error);
                    toast.error("Error submitting form");
                  });
                });
              });
            });
          }
        });

        iframeObserver.observe(document.body, { childList: true, subtree: true });

        toast.dismiss(tost_id);
        toast.success("Your Template is ready to go", {});
      } catch (error) {
        toast.update(tost_id, { render: "An error occurred", type: "error", isLoading: false });
      }
    };

    if (status === "upload") {
      fetch_templates();
    } else {
      const editor = grapesjs.init({
        container: '#gjs',
        width: '100%',
        height: '100vh',
        fromElement: false,
        plugins: [websitePlugin, basicBlockPlugin, formPlugin],
        // Your panels configuration (if necessary)
      });
      
      // Function to close all categories
      const closeAllCategories = () => {
        const categories = editor.BlockManager.getCategories();
        categories.each(category => {
          category.set('open', false); // Close each category
        });
      };
      
      // Ensure blocks panel categories are closed initially
      editor.on('load', () => {
        closeAllCategories();
      });
      
      // If you have dynamic additions of blocks or categories, ensure to close them too
      editor.on('block:add', () => {
        closeAllCategories();
      });
      
      custom_form_block(editor);
      navigation_block(editor);
      feature_block(editor);
      about_block(editor);
      gallery_form_block(editor);
      testimonial_form_block(editor);
      footer_block(editor);
      thank_you_block(editor);
      
      editorRef.current = editor;

      // Use MutationObserver to wait for the iframe to be ready
      const iframeObserver = new MutationObserver(() => {
        const iframe = editor.Canvas.getFrameEl();
        if (iframe) {
          iframeObserver.disconnect();
          iframe.addEventListener('load', () => {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            iframeDocument.querySelectorAll('form').forEach(form => {
              form.addEventListener('submit', function(event) {
                event.preventDefault();
                const formEmail = form.querySelector('input[type="email"]').value;

                fetch(lead_api, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    form_email: formEmail,
                    user_email: useremail,
                    user_id: user_id,
                    name: templateName
                  })
                })
                .then(response => response.json())
                .then(data => {
                  console.log('Success:', data);
                  toast.success("Form submitted successfully");
                })
                .catch((error) => {
                  console.error('Error:', error);
                  toast.error("Error submitting form");
                });
              });
            });
          });
        }
      });

      iframeObserver.observe(document.body, { childList: true, subtree: true });
    }
  }, [status, lead_api, useremail, user_id, templateName, params]);

  const handleInputChange = (event) => {
    setTemplateName(event.target.value);
  };

  const about_block = (editor) => {
    const blocks = [
      { id: 'about-1', label: 'Simple', url: '/blocks/About/simpleabout.html', image: 'https://img.icons8.com/?size=80&id=110481&format=png' },
      { id: 'about-2', label: 'Modern', url: '/blocks/About/mordenabout.html', image: 'https://img.icons8.com/?size=80&id=110481&format=png' },
    ];

    const fetchBlockContent = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.text();
      } catch (error) {
        return '';
      }
    };

    const addBlocks = () => {
      blocks.forEach(block => {
        editor.BlockManager.add(block.id, {
          label: `
            <div>
              <img src="${block.image}" alt="${block.label}" style="width: 50px; height: 50px;" />
              <div style="margin-top:10px">${block.label}</div>
            </div>
          `,
          content: { type: 'text', attributes: { 'data-url': block.url } }, // Placeholder content with URL
          category: {
            id: 'about-category',
            label: 'About',
            open: false
          }
        });
      });
    };

    addBlocks();

    editor.on('component:add', async (component) => {
      const url = component.getAttributes()['data-url'];
      if (url) {
        const content = await fetchBlockContent(url);
        if (content) {
          component.components(content);
        }
      }
    });
  };

  const thank_you_block = (editor) => {
    const blocks = [
      { id: 'thank-you-1', label: 'Simple', url: '/blocks/ThankYou/Simple_thankyou.html', image: '/blocks/ThankYou/Thanks.png' },
      { id: 'thank-you-2', label: 'Modern', url: '/blocks/ThankYou/Morden_thankyou.html', image: '/blocks/ThankYou/modern_thanks.png' },
      { id: 'thank-you-3', label: 'Minimalist', url: '/blocks/ThankYou/Minimalist_thankyou.html', image: '/blocks/ThankYou/Thanks.png' },
      { id: 'thank-you-4', label: 'Colorful', url: '/blocks/ThankYou/Colorful_thankyou.html', image: '/blocks/ThankYou/thanks_colour.png' },
    ];

    const fetchBlockContent = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.text();
      } catch (error) {
        return '';
      }
    };

    const addBlocks = () => {
      blocks.forEach(block => {
        editor.BlockManager.add(block.id, {
          label: `
            <div>
              <img src="${block.image}" alt="${block.label}" style="width: 50px; height: 50px;" />
              <div style="margin-top:10px">${block.label}</div>
            </div>
          `,
          content: { type: 'text', attributes: { 'data-url': block.url } }, // Placeholder content with URL
          category: {
            id: 'thank-you-category',
            label: 'Thank You',
            open: false
          }
        });
      });
    };

    addBlocks();

    editor.on('component:add', async (component) => {
      const url = component.getAttributes()['data-url'];
      if (url) {
        const content = await fetchBlockContent(url);
        if (content) {
          component.components(content);
        }
      }
    });
  };

  const navigation_block = (editor) => {
    const blocks = [
      { id: 'nav-1', label: 'Light Theme', url: '/blocks/Navigation/nav1.html', image: 'https://img.icons8.com/?size=96&id=Rdp3AydLFY2A&format=png' },
      { id: 'nav-2', label: 'Dark Theme', url: '/blocks/Navigation/darknav.html', image: 'https://img.icons8.com/?size=96&id=Rdp3AydLFY2A&format=png' },
      { id: 'nav-3', label: 'Single', url: '/blocks/Navigation/singlenavigation.html', image: 'https://img.icons8.com/?size=96&id=Rdp3AydLFY2A&format=png' },
    ];

    const fetchBlockContent = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.text();
      } catch (error) {
        return '';
      }
    };

    const addBlocks = () => {
      blocks.forEach(block => {
        editor.BlockManager.add(block.id, {
          label: `
            <div>
              <img src="${block.image}" alt="${block.label}" style="width: 50px; height: 50px;" />
              <div style="margin-top:10px">${block.label}</div>
            </div>
          `,
          content: { type: 'text', attributes: { 'data-url': block.url } }, // Placeholder content with URL
          category: {
            id: 'navigation-category',
            label: 'Navigation',
            open: false
          }
        });
      });
    };

    addBlocks();

    editor.on('component:add', async (component) => {
      const url = component.getAttributes()['data-url'];
      if (url) {
        const content = await fetchBlockContent(url);
        if (content) {
          component.components(content);
        }
      }
    });
  };

  const feature_block = (editor) => {
    const blocks = [
      { id: 'feature-1', label: 'Normal Light', url: '/blocks/Features/featureLight.html', image: 'https://cdn-icons-png.flaticon.com/128/10435/10435176.png' },
      { id: 'feature-2', label: 'Modern', url: '/blocks/Features/featureMorden.html', image: 'https://cdn-icons-png.flaticon.com/128/10435/10435176.png' },
    ];

    const fetchBlockContent = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.text();
      } catch (error) {
        return '';
      }
    };

    
    const addBlocks = () => {
      blocks.forEach(block => {
        editor.BlockManager.add(block.id, {
          label: `
            <div>
              <img src="${block.image}" alt="${block.label}" style="width: 50px; height: 50px;" />
              <div style="margin-top:10px">${block.label}</div>
            </div>
          `,
          content: { type: 'text', attributes: { 'data-url': block.url } }, // Placeholder content with URL
          category: {
            id: 'feature-category',
            label: 'Features',
            open: false
          }
        });
      });
    };

    addBlocks();

    editor.on('component:add', async (component) => {
      const url = component.getAttributes()['data-url'];
      if (url) {
        const content = await fetchBlockContent(url);
        if (content) {
          component.components(content);
        }
      }
    });
  };

  const custom_form_block = (editor) => {
    const blocks = [
      { id: 'custom-form-1', label: 'Rich', url: '/blocks/Custom_Forms/Rich.html', image: 'https://img.icons8.com/?size=64&id=3qwzQjAQr9Zi&format=png' },
      { id: 'custom-form-2', label: 'Letter', url: '/blocks/Custom_Forms/Latter.html', image: 'https://img.icons8.com/?size=64&id=3qwzQjAQr9Zi&format=png' },
      { id: 'custom-form-3', label: 'Dark', url: '/blocks/Custom_Forms/Dark.html', image: 'https://img.icons8.com/?size=64&id=3qwzQjAQr9Zi&format=png' },
      { id: 'custom-form-4', label: 'Dark', url: '/blocks/Custom_Forms/Basic.html', image: 'https://img.icons8.com/?size=64&id=3qwzQjAQr9Zi&format=png' }
    ];

    const fetchBlockContent = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.text();
      } catch (error) {
        return '';
      }
    };

    const addBlocks = () => {
      blocks.forEach(block => {
        editor.BlockManager.add(block.id, {
          label: `
          <div>
            <img src="${block.image}" alt="${block.label}" style="width: 50px; height: 50px;" />
            <div style="margin-top:10px">${block.label}</div>
          </div>
        `,
          content: { type: 'text', attributes: { 'data-url': block.url } }, // Placeholder content with URL
          category: {
            id: 'custom-forms-category',
            label: 'Contact Us',
            open: false
          }
        });
      });
    };

    addBlocks();

    editor.on('component:add', async (component) => {
      const url = component.getAttributes()['data-url'];
      if (url) {
        const content = await fetchBlockContent(url);
        if (content) {
          component.components(content);
        }
      }
    });
  };


  const testimonial_form_block = (editor) => {
    const blocks = [
      { id: 'testimonial-form-1', label: 'Image Card', url: '/blocks/Testimonials/ImageCard.html', image: 'https://img.icons8.com/?size=80&id=x3VvdArMbBx8&format=png' },
      { id: 'testimonial-form-2', label: 'Modern', url: '/blocks/Testimonials/Modern.html', image: 'https://img.icons8.com/?size=80&id=x3VvdArMbBx8&format=png' },
      { id: 'testimonial-form-3', label: 'Multiple', url: '/blocks/Testimonials/Multiple.html', image: 'https://img.icons8.com/?size=80&id=x3VvdArMbBx8&format=png' },
      { id: 'testimonial-form-4', label: 'Overlaying', url: '/blocks/Testimonials/Overlaying.html', image: 'https://img.icons8.com/?size=80&id=x3VvdArMbBx8&format=png' }
    ];

    const fetchBlockContent = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.text();
      } catch (error) {
        return '';
      }
    };

    const addBlocks = () => {
      blocks.forEach(block => {
        editor.BlockManager.add(block.id, {
          label: `
          <div>
            <img src="${block.image}" alt="${block.label}" style="width: 50px; height: 50px;" />
            <div style="margin-top:10px">${block.label}</div>
          </div>
        `,
          content: { type: 'text', attributes: { 'data-url': block.url } }, // Placeholder content with URL
          category: {
            id: 'testimonials-forms-category',
            label: 'Testimonials',
            open: false
          }
        });
      });
    };

    addBlocks();

    editor.on('component:add', async (component) => {
      const url = component.getAttributes()['data-url'];
      if (url) {
        const content = await fetchBlockContent(url);
        if (content) {
          component.components(content);
        }
      }
    });
  }

  const footer_block = (editor) => {
    const blocks = [
      { id: 'animated-form-1', label: 'Animated', url: '/blocks/Footer/Animated.html', image: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/footer-12081383-9853492.png?f=webp&w=256' },
      { id: 'animated-form-2', label: 'Wave', url: '/blocks/Footer/Wave.html', image: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/footer-12081383-9853492.png?f=webp&w=256' },
      { id: 'animated-form-3', label: 'Light', url: '/blocks/Footer/Light.html', image: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/footer-12081383-9853492.png?f=webp&w=256' },
      { id: 'animated-form-4', label: 'Dark', url: '/blocks/Footer/Dark.html', image: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/footer-12081383-9853492.png?f=webp&w=256' }
    ];

    const fetchBlockContent = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.text();
      } catch (error) {
        return '';
      }
    };

    const addBlocks = () => {
      blocks.forEach(block => {
        editor.BlockManager.add(block.id, {
          label: `
          <div>
            <img src="${block.image}" alt="${block.label}" style="width: 50px; height: 50px;" />
            <div style="margin-top:10px">${block.label}</div>
          </div>
        `,
          content: { type: 'text', attributes: { 'data-url': block.url } }, // Placeholder content with URL
          category: {
            id: 'footer-category',
            label: 'Footer',
            open: false
          }
        });
      });
    };

    addBlocks();

    editor.on('component:add', async (component) => {
      const url = component.getAttributes()['data-url'];
      if (url) {
        const content = await fetchBlockContent(url);
        if (content) {
          component.components(content);
        }
      }
    });
  }


  const gallery_form_block = (editor) => {
    const blocks = [
      { id: 'gallery-form-1', label: 'LookBook', url: '/blocks/Gallery/LookBook.html', image: 'https://cdn.iconscout.com/icon/premium/png-512-thumb/gallery-4691910-3889553.png?f=webp&w=256' },
      { id: 'gallery-form-2', label: 'Frame', url: '/blocks/Gallery/Slider.html', image: 'https://cdn.iconscout.com/icon/premium/png-512-thumb/gallery-4691910-3889553.png?f=webp&w=256' },
      ];

    const fetchBlockContent = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.text();
      } catch (error) {
        return '';
      }
    };

    const addBlocks = () => {
      blocks.forEach(block => {
        editor.BlockManager.add(block.id, {
          label: `
          <div>
            <img src="${block.image}" alt="${block.label}" style="width: 50px; height: 50px;" />
            <div style="margin-top:10px">${block.label}</div>
          </div>
        `,
          content: { type: 'text', attributes: { 'data-url': block.url } }, // Placeholder content with URL
          category: {
            id: 'gallery-forms-category',
            label: 'Gallery',
            open: false
          }
        });
      });
    };

    addBlocks();

    editor.on('component:add', async (component) => {
      const url = component.getAttributes()['data-url'];
      if (url) {
        const content = await fetchBlockContent(url);
        if (content) {
          component.components(content);
        }
      }
    });
  }
  const handleSave = () => {
    const editor = editorRef.current;
    if (!editor) {
      toast.error('Editor instance not found');
      return;
    }
    let html = editor.getHtml();
    const css = editor.getCss();

    if (!templateName) {
      toast.error('Template name is empty');
      return;
    }

    // Define the JavaScript code as a string
    const formScript = `
      document.addEventListener('DOMContentLoaded', function() {
        document.querySelectorAll('form').forEach(form => {
          // Change the button type to submit
          form.querySelector('button[type="button"]').setAttribute('type', 'submit');
          form.addEventListener('submit', function(event) {
            event.preventDefault();
            const formEmail = form.querySelector('input[type="email"]').value;
            fetch('${lead_api}', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                form_email: formEmail,
                user_email: '${useremail}',
                user_id: '${user_id}',
                name: '${templateName}'
              })
            })
            .then(response => response.json())
            .then(data => {
              console.log('Success:', data);
              alert('Form submitted successfully'); // You can replace this with a toast message
            })
            .catch((error) => {
              console.error('Error:', error);
              alert('Error submitting form'); // You can replace this with a toast message
            });
          });
        });
      });
    `;

    // Inject the form handling script into the HTML
    html += `<script type="text/javascript">${formScript}</script>`;

    const tost_id = toast.loading("Please wait...");
    fetch('https://dashboard.sendwithhma.com/api/save-template', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ html, css, templateName, useremail }),
    })
      .then(response => {
        if (!response.ok) {
          toast.update(tost_id, { render: "Something went wrong", type: "error", isLoading: false });
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        if (data === null) {
          toast.update(tost_id, { render: "Name already Exists", type: "error", isLoading: false });
        } else if (data.reload) {
          toast.update(tost_id, { render: "WOW! Your site is Ready..😊", type: "success", isLoading: false });
          window.addEventListener('message', function (event) {
            if (event.data.reloadNeeded) {
              window.parent.location.reload();
            }
          });
        } else {
          toast.update(tost_id, { render: "Name Already exist", type: "error", isLoading: false });
        }
      })
      .catch(error => {
        toast.update(tost_id, { render: "Something went wrong err", type: "error", isLoading: false });
      });
  };

  return (
    <div className="div-style">
      <div>
        <ToastContainer />
      </div>

      <div className="row-container-text">
         <div className="custom" id="name_div">
            <div className="input-container">
            <input
            type="text"
            id="fname"
            name="fname"
            value={templateName}
            aria-labelledby="label-fname"
            onChange={handleInputChange}
            />
          <label className="label" htmlFor="fname" id="label-fname">
            <div className="text">Template Name</div>
          </label>
        </div>
        </div>
        
        <label for="checkboxInput" class="bookmark" >
          <input type="checkbox" id="checkboxInput" onClick={handleSave} />
             <svg
               width="15"
               viewBox="0 0 50 70"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
               class="svgIcon"
             >
            <path
                d="M46 62.0085L46 3.88139L3.99609 3.88139L3.99609 62.0085L24.5 45.5L46 62.0085Z"
                stroke="black"
                stroke-width="7"
            ></path>
         </svg>
       </label>
      </div>
      
      <div id="gjs"></div>
      
    </div>
  );
}

export default App;
